import React from 'react'

export default function BigHeader() {
  return (
    <header>
        <div class="page-header min-vh-70"
            loading="lazy">
            <span class="mask maskHeader opacity-6"></span>
            <div class="container">
                <div class="rowJuMet">
                    <div class="colHeader col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                        <img class="img-logo" src="./img/logo.png" alt="logo BeckToPuur" />
                        <h2 class="text-background mb-4">Ju met 't peird</h2>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}
