import React, { useEffect, useState } from 'react'
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    where,
    query,
    orderBy
} from "firebase/firestore";
import {
    ref,
    deleteObject,
    getDownloadURL,
    listAll,
    list,
} from "firebase/storage";
import { auth, db, storage } from "./../firebase-config";
import { async } from 'q';

const Fotos = () => {
    const [agendaView, setAgendaView] = useState('Loading');

    const albumCollectionRef = collection(db, "fotoAlbums");

    async function makeAgendaView(table) {
        var fotoUrl = [];
        console.log(table.docs[0].id);
        console.log(table.docs.id);
        for (let index = 0; index < table.docs.length; index++) {
            const element = table.docs[index];
            console.log(element);
            const listRef = ref(storage, `images/${ element.id }`);
            const listFotos = await listAll(listRef);
            console.log(listFotos);
            fotoUrl[index] = await getDownloadURL(listFotos.items[0]);
            console.log(fotoUrl);
        }
        table.docs.forEach(element => {
            console.log(element.id);
        });
        return table.docs.map((item, index) => (
            <div class="col-md-4">
                <div class="card mt-3">
                    <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
                        <a href={ '/fotooverview?id='+item.id } class="d-block">
                            <img src={fotoUrl[index]} class="img-fluid border-radius-lg"/>
                        </a>
                    </div>

                    <div class="card-body pt-2">
                        <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{ item.data().datum.split('-')[2] }/{ item.data().datum.split('-')[1] }/{ item.data().datum.split('-')[0] }</span>
                        <a href={ '/fotooverview?id='+item.id } class="card-title h5 d-block text-darker">
                            { item.data().titel }
                        </a>
                    </div>
                </div>
            </div>
        ));
    }

    useEffect(() => {
        async function loading() {
            const q = query(albumCollectionRef, where("visible", "==", true), orderBy("datum", "desc"));
            const agenda = await getDocs(q);
            var tabel = await makeAgendaView(agenda);
            setAgendaView(tabel);
        }
        loading();
    }, []);

    return (
        <div class="container">
            <div class="row">
                {agendaView}
            </div>
        </div>
    )
}

export default Fotos
