import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
    collection,
    getDoc,
    doc,
    updateDoc,
    where,
    query,
    orderBy
} from "firebase/firestore";
import {
    ref,
    deleteObject,
    getDownloadURL,
    listAll,
    list,
} from "firebase/storage";
import { auth, db, storage } from "../firebase-config";
import { async } from 'q';

const Fotooverview = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [uid, setUid] = useState('');
    const [titel, setTitel] = useState('');
    const [visible, setVisible] = useState(true);
    const [datum, setDatum] = useState('0-0-0');
    const [omschrijving, setOmschrijving] = useState('');
    const [imageUrls, setImageUrls] = useState([]);
    const [imageModal, setImageModal] = useState(0);
    const [imageItem, setImageItem] = useState([]);
    const navigate = useNavigate();

    const loadFotos = async (preUid) => {
        const listRef = ref(storage, `images/${ preUid }`);
        listAll(listRef).then((response) => {
            console.log(response);
            setImageUrls([]);
            setImageItem([]);
            response.items.forEach((item, index) => {
                setImageItem((prev) => [...prev, item]);
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => {
                        var newArray = [...prev, url];
                        return newArray.sort()
                    });
                    // setImageUrls(imageUrls.sort());
                });
            });
            
        });
    }

    const vorige = () => {
        setImageModal(imageModal === 0 ? imageUrls.length - 1 : imageModal - 1);
    }

    const volgende = () => {
        setImageModal(imageModal === imageUrls.length - 1 ? 0 : imageModal + 1);
    }

    function setImage(number) {
        setImageModal(number);
    }

    useEffect(() => {
        async function loadingOverview() {
            console.log(searchParams.get("id"));
            setUid(searchParams.get("id"));
            const docRef = doc(db, "fotoAlbums", searchParams.get("id"));
            const docAgenda = await getDoc(docRef);
            if (docAgenda.exists()) {
                console.log("Document data:", docAgenda.data());
                setTitel(docAgenda.data().titel);
                setVisible(docAgenda.data().visible);
                setDatum(docAgenda.data().datum);
                setOmschrijving(docAgenda.data().omschrijving);
                if (docAgenda.data().visible == false) {
                    navigate('/fotos')
                }
                loadFotos(searchParams.get("id"));
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                navigate('/fotos');
            }
        }
        loadingOverview();
    }, []);

    return (
        <div class="container">
            <h3 class="mt-4 text-secondary">{ datum.split('-')[2] }/{ datum.split('-')[1] }/{ datum.split('-')[0] }: {titel}</h3>
            <span class="borderTitel"></span>
            <p class="pe-5 text-zwart">
                {omschrijving}
            </p>
            <div class="row">
            {imageUrls.map((item, index) => (
                <div class="col-md-4">
                    <div class="card mt-3">
                        <div class="card-header p-0 mx-0 mt-0 position-relative z-index-1">
                            <a  class="d-block" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setImage(index) }>
                                <img src={imageUrls[index]} class="img-fluid border-radius-lg"/>
                            </a>
                        </div>
                    </div>
                </div>
            ))}
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-body">
                    <img src={imageUrls[imageModal]} class="img-fluid border-radius-lg"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-primary" onClick={vorige}>Vorige</button>
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-primary" onClick={volgende}>Volgende</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Fotooverview
