import React, { useEffect, useState } from 'react'
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    where,
    query,
    orderBy
} from "firebase/firestore";
import { auth, db } from "./../firebase-config";
import { element } from 'prop-types';

// export default class Agenda extends Component {
const Agenda = () => {
    const [agendaView, setAgendaView] = useState('');

    const agendaCollectionRef = collection(db, "agenda");

    function makeAgendaView(table) {
        return table.docs.map((item, index) => (
            <>
                <h5>{ item.data().datum.split('-')[2] }/{ item.data().datum.split('-')[1] }/{ item.data().datum.split('-')[0] }: <h2>&emsp;{ item.data().titel }</h2></h5>
                <p>
                    { item.data().omschrijving.split('\n').map(element => ( <>&emsp;&emsp;&emsp;{element}<br/></>)) }
                </p>
            </>
        ));
    }

    useEffect(() => {
        async function loading() {
            var today = new Date(),
            date = today.getFullYear() + '-' + ((today.getMonth()+1) <10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0' : '') + today.getDate();
            const q = query(agendaCollectionRef, 
                where("datum", ">=", date),
                where("visible", "==", true),
                orderBy("datum"));
            const agenda = await getDocs(q);
            var tabel = makeAgendaView(agenda);
            setAgendaView(tabel);
        }
        loading();
    }, []);

    return (
        <div class="container">
            <br/>
            <div class="row rowContent agendaContent">
                <h1>Agenda Ju met 't peird en Trekpaardenvrienden:</h1>
                {agendaView}
            </div>
        </div>
    )
}

export default Agenda;
