import React from "react";
// import { Switch, Route, Redirect } from "react-router";
import { HashRouter, Route, Routes } from 'react-router-dom'

import Navbar from "./Navbar";
import BigHeader from "./BigHeader";
import FooterBotttem from "./FooterBottem";

import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Agenda from "./Pages/Agenda";
import Fotos from "./Pages/Fotos";
import FotoOverview from "./Pages/Fotooverview";
import Contact from "./Pages/Contact";
import ContactSend from "./ContactSend";

function App() {
  return (
    <div className="App">
      <Navbar />
      <BigHeader />
      <Routes>
        <Route exact path="/contactSend" element={<ContactSend />} />
      </Routes>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/index" element={<Home />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/fotos" element={<Fotos />} />
        <Route path="/fotooverview" element={<FotoOverview />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contactSend" element={<Contact />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <FooterBotttem />
    </div>
  );
}

export default App;
