import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
            <div class="container">
                {/* <div class="row rowContent">
                    <img class="img-homePageL contentBig contatVlees" src="./img/Contact/Contact1.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/Contact/Contact1.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Werken/koken met BeckToPuur?</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Contacteer ons als u ons vlees wilt verkijgen!</h5>
                    <p class="pe-5 text-zwart left50" id='contact'>
                        Wij leveren voornamelijk aan slagers, hoevewinkels en consumenten die met duurzaam varkensvlees willen werken/koken.
                    </p>
                </div>
                <hr /> */}
                <div class="row rowContent">
                    <img class="img-homePageR contentBig contatVlees" src="./img/Contact/contact.jpg" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/Contact/contact.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary">Contact gegevens</h3>
                    <p class="text-zwart"><i class="fas fa-home me-3"></i> Minderhout</p>
                    <p class="text-zwart">
                        <a href="mailto:info@jumettpeird.be" class="text-zwart">
                            <i class="fas fa-envelope me-3"></i>
                            info@jumettpeird.be
                        </a>
                    </p>
                    <p class="text-zwart"><i class="fas fa-phone me-3"></i> + 32 495 67 21 00</p>
                </div>
            </div>
        )
    }
}
