import React from 'react'

function Home() {
  return (
    <div class="container">
        <div class="rowJuMet rowContent">
            <img class="img-homePageR contentBig" src="./img/Home/wie.jpg" alt="first foto" />
            <img class="img-homePageR contentSmall" src="./img/Home/wie.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary">Wie zijn we?</h3>
            <span class="borderTitel"></span>
            {/* <h5 class="mt-4 text-secondary">Activeiten met paarden en trekpaarden</h5> */}
            <p class="pe-5 text-zwart">
                Onze club werd opgericht in 2017 in de Noorderkempen. 
                We zijn paardenliefhebbers die ervan houden om samen te genieten van de natuur en onze paarden. 
                De meeste paarden zijn trekpaarden, zonder voorkeur voor een bepaald ras. 
                Paarden, pony’s en kruisingen maken ook deel uit van de club. Onze leden zijn mensen tussen 6 en 99 jaar, kortom, iedereen is welkom!
            </p>
        </div>
        <hr />
        <div class="rowJuMet rowContent">
            <img class="img-homePageL contentBig" src="./img/Home/wat.jpg" alt="first foto" />
            <img class="img-homePageL contentSmall" src="./img/Home/wat.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary left50">Wat doen we?</h3>
            <span class="borderTitel"></span>
            {/* <h5 class="mt-4 text-secondary left50">Ben je geintereseerd kom zeker eens deelnemen of kijken</h5> */}
            <p class="pe-5 text-zwart left50">
                Maandelijks organiseren we een tocht met de paarden en de koetsen met de mogelijkheid om ook onder het zadel deel te nemen. 
                Na de oogst en voor het zaaien organiseren we ploegdagen op velden die we mogen gebruiken van landbouwers uit de buurt. 
                Dit zijn geen wedstrijden, integendeel, deze dagen zijn ideaal om jonge paarden op te leren en ervaren paarden te laten werken. 
                Ook tips en tricks aan de voermannen en vrouwen worden graag uitgewisseld.
                Aan landbouwdagen, processies en optochten nemen we graag deel binnen onze mogelijkheden.
                1 keer per jaar wordt er een 3-daagse tocht georganiseerd. 
                We rijden 3 dagen met onze koetsen door mooie landschappen en overnachten op een kampeerterrein bij een landbouwer. 
                ‘s Avonds genieten we van elkaars gezelschap en een drankje. 

                Kom je graag een kijkje nemen, de sfeer opsnuiven of denk je eraan om je aan te sluiten bij Ju met’t peird, bekijk zeker onze agenda. 

            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="agenda" rel="Ons verhaal"
                title="Ons verhaal" data-placement="bottom" role="button">
                Klik hier voor agenda
            </a>
        </div>
        <hr />
        <div class="rowJuMet rowContent">
            <img class="img-homePageR contentBig" src="./img/Home/fotos.jpg" alt="first foto" />
            <img class="img-homePageR contentSmall" src="./img/Home/fotos.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary">Foto's</h3>
            <span class="borderTitel"></span>
            {/* <h5 class="mt-4 text-secondary">Vorige Activeiten zijn te bekijken in onze foto's</h5> */}
            <p class="pe-5 text-zwart">
                Omdat foto’s meer zeggen dan woorden, klik zeker door!
                Voor de originele foto’s kan je een e- mail sturen naar het bestuur via <a href="mailto:info@jumettpeird.be">info@jumettpeird.be</a>.
            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="fotos" rel="Ons verhaal"
                title="Ons verhaal" data-placement="bottom" role="button">
                Bekijk onze foto's
            </a>
        </div>
    </div>
  )
}

export default Home